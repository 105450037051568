import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../utils/grid-util';
import { FilterElementType } from '../../../utils/filter-util';
import { numberWithCommas } from '../../../utils/data-format';
import { CELL_TYPE_DETAIL_ACTION } from '../../../utils/row-data-util';
import { INSPECTION_RECEPTION_STATE_CODE } from '../../../utils/const';

const userData: (
	adminName: string,
	mainRole: boolean | undefined,
	proxyInspectionReceptionRegistrationDate: string,
	departmentName: string | undefined,
) => GridDataModel = (
	adminName: string,
	mainRole,
	proxyInspectionReceptionRegistrationDate,
	departmentName,
) => {
	return {
		formInitialValues: {
			proxyInspectionOrderCompanyName: departmentName || '',
			proxyInspectionReceptionRegistrationDate: [
				dayjs().add(-3, 'months'),
				dayjs(),
			],
		},
		colsList: [
			{
				tableInfo: {
					field: 'detail',
					headerName: '관리',
				},
				options: {
					pinned: 'left',
					width: ColSize.CW_SMALL,
					cellRenderer: CELL_TYPE_DETAIL_ACTION,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionOrderCompanyName',
					headerName: '발주처',
				},
				options: {
					pinned: 'left',
					width: ColSize.CW_SMALL,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionReceptionRegistrationDate',
					headerName: '발주일',
				},
				options: {
					pinned: 'left',
					width: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'carLicensePlateNumber',
					headerName: '차량번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const plateNumber = params.value;
						if (plateNumber && plateNumber.length > 2) {
							const masked = `${plateNumber.slice(0, -4)}**${plateNumber.slice(
								-2,
							)}`;
							return masked;
						}
						return plateNumber;
					},
				},
			},
			{
				tableInfo: {
					field: 'modifyCarLicensePlateNumber',
					headerName: '변경번호',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'carName',
					headerName: '차량모델',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'carType',
					headerName: '차종',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionOrderType',
					headerName: '검사유형',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionResult',
					headerName: '진행결과',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionShopName',
					headerName: '검사소',
				},
				options: {
					minWidth: ColSize.CW_LARGE,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionDate',
					headerName: '검사일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionCarMileage',
					headerName: '주행거리',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && `${numberWithCommas(value)} km`;
					},
				},
			},
			{
				tableInfo: {
					field: 'localManagerName',
					headerName: '지역담당자',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionType',
					headerName: '검사종류',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionResultDate',
					headerName: '검사완료일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'publicCorporationInspectionReceptionNo',
					headerName: '공단접수번호',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'customerName',
					headerName: '고객명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'confirmationPhoneNumber',
					headerName: '최종연락처',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: (params: any) => {
						const phoneNumber = params.value;
						if (phoneNumber) {
							// Find the index of the first hyphen
							const firstHyphenIndex = phoneNumber.indexOf('-');
							if (firstHyphenIndex !== -1) {
								// Mask everything after the first hyphen
								const masked = `${phoneNumber.slice(
									0,
									firstHyphenIndex + 1,
								)}****${phoneNumber.slice(firstHyphenIndex + 5)}`;
								return masked;
							}
						}
						return phoneNumber;
					},
				},
			},
			{
				tableInfo: {
					field: 'modifyCustomerAddress',
					headerName: '확정주소',
				},
				options: {
					minWidth: ColSize.CW_LONG_TEXT,
					flex: 1,
					cellRenderer: (params: any) => {
						const address = params.value;
						if (address) {
							const maxLength = 10;
							if (address.length > maxLength) {
								return `${address.slice(0, maxLength)}...`;
							}
						}
						return address;
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionReceptionMemo',
					headerName: '고객요청사항',
				},
				options: {
					minWidth: ColSize.CW_DESCRIPTION,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionReceptionCancellationDate',
					headerName: '불가보고일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionReceptionCancellationReason',
					headerName: '불가사유',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'rwManagerName',
					headerName: '통화담당자',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionReceptionChannel',
					headerName: '접수처',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'carRegistrationDate',
					headerName: '차량등록일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'carInspectionRestrictedDate',
					headerName: '검사만료일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'carInspectionType',
					headerName: '검사종류',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'carAgeRestrictedDate',
					headerName: '차량만료일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentDate',
					headerName: '지급일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentName',
					headerName: '지급대상',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentAmount',
					headerName: '지급금액',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return numberWithCommas(value);
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentBank',
					headerName: '지급은행',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentBankName',
					headerName: '지급은행명',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentAccount',
					headerName: '지급계좌',
				},
				options: {
					minWidth: ColSize.CW_MEDIUM,
					flex: 1,
					cellRenderer: (params: any) => {
						const accountNumber = params.value;
						if (accountNumber) {
							// Mask the middle part of the account number
							const masked = `${accountNumber.slice(
								0,
								3,
							)}****${accountNumber.slice(-4)}`;
							return masked;
						}
						return accountNumber;
					},
				},
			},
			{
				tableInfo: {
					field: 'proxyInspectionAmountPaymentMemo',
					headerName: '지급메모',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'paymentState',
					headerName: '정산상태',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
			{
				tableInfo: {
					field: 'paymentStateModifyDate',
					headerName: '정산상태변경일',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
					cellRenderer: (params: any) => {
						const { value } = params;
						return value && dayjs(value).format('YYYY-MM-DD');
					},
				},
			},
			{
				tableInfo: {
					field: 'paymentType',
					headerName: '지급유형',
				},
				options: {
					minWidth: ColSize.CW_SMALL,
					flex: 1,
				},
			},
		],
		forms: [
			{
				key: 'proxyInspectionOrderCompanyName',
				label: '발주처',
				type: FilterElementType.INPUT,
			},
			{
				key: 'proxyInspectionReceptionRegistrationDate',
				label: '접수일자',
				type: FilterElementType.DATE_RANGE_PICKER,
			},
			{
				key: 'proxyInspectionReceptionStateCode',
				label: '진행상태',
				type: FilterElementType.SELECT,
				options: [
					{ label: '전체', value: '' },
					...INSPECTION_RECEPTION_STATE_CODE,
				],
			},
		],
		// forms: mainRole
		// 	? [
		// 			{
		// 				key: 'proxyInspectionOrderCompanyName',
		// 				label: '발주처',
		// 				type: FilterElementType.INPUT,
		// 			},
		// 			{
		// 				key: 'proxyInspectionReceptionRegistrationDate',
		// 				label: '접수일자',
		// 				type: FilterElementType.DATE_RANGE_PICKER,
		// 			},
		// 			{
		// 				key: 'proxyInspectionReceptionStateCode',
		// 				label: '진행상태',
		// 				type: FilterElementType.SELECT,
		// 				options: [
		// 					{ label: '전체', value: '' },
		// 					...INSPECTION_RECEPTION_STATE_CODE,
		// 				],
		// 			},
		// 	  ]
		// 	: [
		// 			{
		// 				key: 'proxyInspectionReceptionRegistrationDate',
		// 				label: '접수일자',
		// 				type: FilterElementType.DATE_RANGE_PICKER,
		// 			},
		// 			{
		// 				key: 'proxyInspectionReceptionStateCode',
		// 				label: '진행상태',
		// 				type: FilterElementType.SELECT,
		// 				options: [
		// 					{ label: '전체', value: '' },
		// 					...INSPECTION_RECEPTION_STATE_CODE,
		// 				],
		// 			},
		// 	  ],
		selectForms: [
			{
				key: 'proxyInspectionReceptionId',
				label: '대행검사접수ID',
				type: FilterElementType.INPUT,
			},
			{
				key: 'proxyInspectionReceptionChannel',
				label: '고객사명',
				type: FilterElementType.INPUT,
			},
			{
				key: 'carLicensePlateNumber',
				label: '차량번호',
				type: FilterElementType.INPUT,
			},
			{
				key: 'customerPhoneNumber1',
				label: '고객연락처',
				type: FilterElementType.PHONE_INPUT,
			},
		],
	};
};

export default userData;
