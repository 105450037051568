import React from 'react';
import { Tag } from 'antd';
import dayjs from 'dayjs';
import { ColSize, GridDataModel } from '../../../../utils/grid-util';
import { FilterElementType } from '../../../../utils/filter-util';
import { numberWithCommas } from '../../../../utils/data-format';
import { CELL_TYPE_DETAIL_ACTION } from '../../../../utils/row-data-util';
import {
	INSPECTION_RECEPTION_STATE_CODE,
	LabelValue,
} from '../../../../utils/const';

export const getInspectionReceptionStateTag = (
	code: LabelValue | undefined,
) => {
	if (code) {
		switch (code.value) {
			case '0':
				return <Tag color="red">{code.label}</Tag>;
			case '2':
				return <Tag color="blue">{code.label}</Tag>;
			case '4':
				return <Tag color="green">{code.label}</Tag>;
			case '5':
				return <Tag color="lime">{code.label}</Tag>;
			case '6':
				return <Tag color="cyan">{code.label}</Tag>;
			case '8':
				return <Tag color="orange">{code.label}</Tag>;
			case '9':
				return <Tag color="purple">{code.label}</Tag>;
			default:
				return <Tag>{code.label}</Tag>;
		}
	}
	return '-';
};

export const getInspectionReceptionState = (code: LabelValue | undefined) => {
	if (code) {
		return code.label;
	}
	return '-';
};

const userData: GridDataModel = {
	formInitialValues: {
		proxyInspectionReceptionRegistrationDate: [
			dayjs().add(-3, 'months'),
			dayjs(),
		],
	},
	colsList: [
		{
			tableInfo: {
				field: 'detail',
				headerName: '관리',
			},
			options: {
				pinned: 'left',
				width: ColSize.CW_SMALL,
				cellRenderer: CELL_TYPE_DETAIL_ACTION,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionStateCode',
				headerName: '진행상태',
			},
			options: {
				pinned: 'left',
				width: ColSize.CW_SMALL,
				flex: 1,
				getQuickFilterText: (params: any) => {
					return getInspectionReceptionState(
						INSPECTION_RECEPTION_STATE_CODE.find(
							(code) => code.value === params.value,
						),
					);
				},
				cellRenderer: (params: any) => {
					const { value } = params;
					return (
						value &&
						getInspectionReceptionStateTag(
							INSPECTION_RECEPTION_STATE_CODE.find(
								(code) => code.value === value,
							),
						)
					);
				},
			},
		},
		{
			tableInfo: {
				field: 'carLicensePlateNumber',
				headerName: '차량번호',
			},
			options: {
				pinned: 'left',
				width: ColSize.CW_SMALL_M,
				cellRenderer: (params: any) => {
					const plateNumber = params.value;
					if (plateNumber && plateNumber.length > 2) {
						const masked = `${plateNumber.slice(0, -4)}**${plateNumber.slice(
							-2,
						)}`;
						return masked;
					}
					return plateNumber;
				},
			},
		},
		{
			tableInfo: {
				field: 'modifyCarLicensePlateNumber',
				headerName: '변경번호',
			},
			options: {
				pinned: 'left',
				width: ColSize.CW_SMALL,
			},
		},
		{
			tableInfo: {
				field: 'rwManagerName',
				headerName: '통화담당자',
			},
			options: {
				width: ColSize.CW_SMALL,
			},
		},
		{
			tableInfo: {
				field: 'lastCustomerCallDate',
				headerName: '최종통화일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionChannel',
				headerName: '접수처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionRegistrationDate',
				headerName: '접수일자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionRoundTrip',
				headerName: '왕복여부',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carName',
				headerName: '차량모델',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carType',
				headerName: '차종',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carRegistrationDate',
				headerName: '차량등록일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'carInspectionRestrictedDate',
				headerName: '검사만료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'carInspectionType',
				headerName: '검사종류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'carAgeRestrictedDate',
				headerName: '차량만료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'customerName',
				headerName: '고객명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const name = params.value;
					if (name && name.length > 1) {
						if (name.length % 2 === 1) {
							const middleIndex = Math.floor(name.length / 2);
							return `${name.slice(0, middleIndex)}*${name.slice(
								middleIndex + 1,
							)}`;
						}
						return `${name[0]}*${name.slice(2)}`;
					}
					return name;
				},
			},
		},
		{
			tableInfo: {
				field: 'customerPhoneNumber1',
				headerName: '고객연락처',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const phoneNumber = params.value;
					if (phoneNumber) {
						// Find the index of the first hyphen
						const firstHyphenIndex = phoneNumber.indexOf('-');
						if (firstHyphenIndex !== -1) {
							// Mask everything after the first hyphen
							const masked = `${phoneNumber.slice(
								0,
								firstHyphenIndex + 1,
							)}****${phoneNumber.slice(firstHyphenIndex + 5)}`;
							return masked;
						}
					}
					return phoneNumber;
				},
			},
		},
		{
			tableInfo: {
				field: 'customerCompanyManagerName',
				headerName: '법인담당자',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'customerCompanyPhoneNumber',
				headerName: '법인연락처',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const phoneNumber = params.value;
					if (phoneNumber) {
						// Find the index of the first hyphen
						const firstHyphenIndex = phoneNumber.indexOf('-');
						if (firstHyphenIndex !== -1) {
							// Mask everything after the first hyphen
							const masked = `${phoneNumber.slice(
								0,
								firstHyphenIndex + 1,
							)}****${phoneNumber.slice(firstHyphenIndex + 5)}`;
							return masked;
						}
					}
					return phoneNumber;
				},
			},
		},
		{
			tableInfo: {
				field: 'confirmationPhoneNumber',
				headerName: '최종연락처',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
				cellRenderer: (params: any) => {
					const phoneNumber = params.value;
					if (phoneNumber) {
						// Find the index of the first hyphen
						const firstHyphenIndex = phoneNumber.indexOf('-');
						if (firstHyphenIndex !== -1) {
							// Mask everything after the first hyphen
							const masked = `${phoneNumber.slice(
								0,
								firstHyphenIndex + 1,
							)}****${phoneNumber.slice(firstHyphenIndex + 5)}`;
							return masked;
						}
					}
					return phoneNumber;
				},
			},
		},
		{
			tableInfo: {
				field: 'customerAddress',
				headerName: '요청주소',
			},
			options: {
				minWidth: ColSize.CW_LONG_TEXT,
				flex: 1,
				cellRenderer: (params: any) => {
					const address = params.value;
					if (address) {
						const maxLength = 10;
						if (address.length > maxLength) {
							return `${address.slice(0, maxLength)}...`;
						}
					}
					return address;
				},
			},
		},
		{
			tableInfo: {
				field: 'modifyCustomerAddress',
				headerName: '확정주소',
			},
			options: {
				minWidth: ColSize.CW_LONG_TEXT,
				flex: 1,
				cellRenderer: (params: any) => {
					const address = params.value;
					if (address) {
						const maxLength = 10;
						if (address.length > maxLength) {
							return `${address.slice(0, maxLength)}...`;
						}
					}
					return address;
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionMemo',
				headerName: '고객요청사항',
			},
			options: {
				minWidth: ColSize.CW_DESCRIPTION,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionCancellationDate',
				headerName: '불가보고일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionCancellationReason',
				headerName: '불가사유',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionOrderDate',
				headerName: '발주일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionOrderType',
				headerName: '검사유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionOrderCompanyName',
				headerName: '발주처',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionResult',
				headerName: '진행결과',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionShopName',
				headerName: '검사소',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionDate',
				headerName: '검사일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionCarMileage',
				headerName: '주행거리',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && `${numberWithCommas(value)} km`;
				},
			},
		},
		{
			tableInfo: {
				field: 'localManagerName',
				headerName: '지역담당자',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionType',
				headerName: '검사종류',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionResultDate',
				headerName: '검사완료일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'publicCorporationInspectionReceptionNo',
				headerName: '공단접수번호',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentDate',
				headerName: '지급일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentName',
				headerName: '지급대상',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentAmount',
				headerName: '지급금액',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return numberWithCommas(value);
				},
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentBank',
				headerName: '지급은행',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentBankName',
				headerName: '지급은행명',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentAccount',
				headerName: '지급계좌',
			},
			options: {
				minWidth: ColSize.CW_MEDIUM,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionAmountPaymentMemo',
				headerName: '지급메모',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'paymentState',
				headerName: '정산상태',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'paymentStateModifyDate',
				headerName: '정산상태변경일',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
				cellRenderer: (params: any) => {
					const { value } = params;
					return value && dayjs(value).format('YYYY-MM-DD');
				},
			},
		},
		{
			tableInfo: {
				field: 'paymentType',
				headerName: '지급유형',
			},
			options: {
				minWidth: ColSize.CW_SMALL,
				flex: 1,
			},
		},
		{
			tableInfo: {
				field: 'proxyInspectionReceptionId',
				headerName: '접수번호',
			},
			options: {
				minWidth: ColSize.CW_LARGE,
				flex: 1,
			},
		},
	],
	forms: [
		{
			key: 'proxyInspectionReceptionRegistrationDate',
			label: '접수일자',
			type: FilterElementType.DATE_RANGE_PICKER,
		},
		{
			key: 'proxyInspectionReceptionStateCode',
			label: '진행상태',
			type: FilterElementType.SELECT,
			options: [
				{ label: '전체', value: '' },
				...INSPECTION_RECEPTION_STATE_CODE,
			],
		},
		{
			key: 'rwManagerName',
			label: '통화담당자',
			type: FilterElementType.INPUT,
		},
	],
	selectForms: [
		{
			key: 'proxyInspectionReceptionId',
			label: '대행검사접수ID',
			type: FilterElementType.INPUT,
		},
		{
			key: 'proxyInspectionReceptionChannel',
			label: '고객사명',
			type: FilterElementType.INPUT,
		},
		{
			key: 'carLicensePlateNumber',
			label: '차량번호',
			type: FilterElementType.INPUT,
		},
		{
			key: 'customerPhoneNumber1',
			label: '고객연락처',
			type: FilterElementType.PHONE_INPUT,
		},
	],
};

export default userData;
