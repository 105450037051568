import React from 'react';
import { Button } from 'antd';
import * as XLSX from 'xlsx'; // Import xlsx
import GridDetailTemplate from '../../../components/templates/GridDetailTemplate';
import ContentBoxWithHeader from '../../../components/molecules/ContentBoxWithHeader';
import {
	useUploadCarServiceExcelMutation,
	useUploadPublicCorpExcelMutation,
} from '../../../store/apis/carService';
import UploadCarServiceExcel from './UploadCarServiceExcel';

const CarServiceExcelUpload = () => {
	const [uploadCarServiceExcel, { isLoading: isCarServiceUpdating }] =
		useUploadCarServiceExcelMutation();

	const [uploadPublicCorpExcel, { isLoading: isPublicCorpUpdating }] =
		useUploadPublicCorpExcelMutation();

	const generateExcelFile = (templateType: 'inspection' | 'publicCorp') => {
		let headers: string[] = [];
		let fileName = '';

		if (templateType === 'inspection') {
			headers = [
				'번호',
				'고객',
				'구분',
				'왕복',
				'요청등록',
				'차량번호',
				'변경번호',
				'차명',
				'차종',
				'차량등록일',
				'검사만료',
				'검사종류',
				'차량만료',
				'고객명',
				'담당자',
				'연락처1',
				'연락처2',
				'연락처3',
				'변경연락처',
				'주소1',
				'주소2',
				'전체주소',
				'변경주소',
				'통화자',
				'발주일',
				'대행/직검',
				'고객요청 및 특이사항/본사회신',
				'발주처',
				'지역담당',
			];
			fileName = '접수정보.xlsx';
		} else if (templateType === 'publicCorp') {
			headers = [
				'접수번호',
				'차량번호',
				'차대번호',
				'성명/상호',
				'차명',
				'검사장소',
				'수검일자',
				'최초등록일',
				'유효기간만료일',
				'차령기간만료일',
				'검사종류',
				'검사결과',
				'Nox여부',
				'주행거리',
			];
			fileName = '공단검사결과.xlsx';
		}

		const ws = XLSX.utils.aoa_to_sheet([headers]);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		XLSX.writeFile(wb, fileName);
	};

	const handleDownloadTemplate = (
		templateType: 'inspection' | 'publicCorp',
	) => {
		generateExcelFile(templateType);
	};

	return (
		<GridDetailTemplate detailTitle="통합 업로드">
			<ContentBoxWithHeader
				title="접수정보 업로드"
				className="inner-content"
				borderRadius="10px"
				rightAccessory={
					<Button
						onClick={() => handleDownloadTemplate('inspection')}
						className="download-button"
					>
						접수정보 템플릿 다운로드
					</Button>
				}
			>
				<UploadCarServiceExcel
					uploadExcel={uploadCarServiceExcel}
					isUpdating={isCarServiceUpdating}
				/>
			</ContentBoxWithHeader>

			<ContentBoxWithHeader
				title="공단검사결과 업로드"
				className="inner-content"
				borderRadius="10px"
				rightAccessory={
					<Button
						onClick={() => handleDownloadTemplate('publicCorp')}
						className="download-button"
					>
						공단검사결과 템플릿 다운로드
					</Button>
				}
			>
				<UploadCarServiceExcel
					uploadExcel={uploadPublicCorpExcel}
					isUpdating={isPublicCorpUpdating}
				/>
			</ContentBoxWithHeader>
		</GridDetailTemplate>
	);
};

export default CarServiceExcelUpload;
