import React, { useContext, useState } from 'react';
import { ToastContext } from '../../../contexts/Toast';
import { axiosInstance } from '../../../network/axios';
import { blobFileDownload } from '../../../utils/file-util';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import DateRangePicker from '../../atoms/DateRangePicker';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { ButtonTypeType } from '../../../styles/theme';
import { companyRegisterNumberFormat } from '../../../utils/data-format';

const PaymentsExcelDownload = () => {
	const toast = useContext(ToastContext);
	const [isLoading, setIsLoading] = useState(false);
	const [dateFrom, setDateFrom] = useState('');
	const [dateTo, setDateTo] = useState('');
	const [managerName, setManagerName] = useState('');
	const [orderCompanyName, setOrderCompanyName] = useState('');
	const [inspectionShopName, setInspectionShopName] = useState('');
	const [paymentName, setPaymentName] = useState('');
	const [companyRegistrationNumber, setCompanyRegistrationNumber] =
		useState('');

	const handleParams = () => {
		const params: {
			proxyInspectionAmountPaymentDateFrom: string;
			proxyInspectionAmountPaymentDateTo: string;
			localManagerName?: string;
			proxyInspectionOrderCompanyName?: string;
			publicCorporationInspectionShopName?: string;
			proxyInspectionAmountPaymentName?: string;
			proxyInspectionAmountPaymentCompanyRegistrationNumber?: string;
		} = {
			proxyInspectionAmountPaymentDateFrom: dateFrom,
			proxyInspectionAmountPaymentDateTo: dateTo,
		};
		if (managerName) {
			params.localManagerName = managerName;
		}
		if (orderCompanyName) {
			params.proxyInspectionOrderCompanyName = orderCompanyName;
		}
		if (inspectionShopName) {
			params.publicCorporationInspectionShopName = inspectionShopName;
		}
		if (paymentName) {
			params.proxyInspectionAmountPaymentName = paymentName;
		}
		if (companyRegistrationNumber) {
			params.proxyInspectionAmountPaymentCompanyRegistrationNumber =
				companyRegistrationNumber;
		}
		return params;
	};

	const downloadFile = async () => {
		setIsLoading(true);
		await axiosInstance
			.get(`/car-services/file-download/accounting`, {
				params: handleParams(),
				responseType: 'blob',
			})
			.then((response) => response.data)
			.then((blob) => {
				const fileName = `정산자료-[${dateFrom}]-[${dateTo}]-[지역담당자]${managerName}-[발주처]${orderCompanyName}-[검사소]${inspectionShopName}-[지급대상]${paymentName}-[사업자번호]${companyRegistrationNumber}.xlsx`;
				blobFileDownload(blob, fileName);
			})
			.catch((err) => {
				if (err.response && err.response.status !== 404) {
					toast('error', '요청을 처리할 수 없습니다.');
				}
				if (err.response && err.response.status === 404) {
					toast('error', '데이터가 없습니다.');
				}
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<ContentBoxWithHeader
			title="정산자료 다운로드"
			className="inner-content"
			borderRadius="10px"
		>
			<GridLayout $columnCount={3}>
				<GridItem title="날짜 선택" span={2}>
					<DateRangePicker
						name="dateRange"
						onChange={(dates, dateStrings) => {
							if (dateStrings) {
								setDateFrom(dateStrings[0]);
								setDateTo(dateStrings[1]);
							}
						}}
					/>
				</GridItem>
				<GridItem title="지역담당자">
					<Input
						name="managerName"
						placeholder="지역담당자"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={managerName}
						onChange={(e) => setManagerName(e.target.value)}
						onReset={() => setManagerName('')}
					/>
				</GridItem>
				<GridItem title="발주처">
					<Input
						name="orderCompanyName"
						placeholder="발주처"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={orderCompanyName}
						onChange={(e) => setOrderCompanyName(e.target.value)}
						onReset={() => setOrderCompanyName('')}
					/>
				</GridItem>
				<GridItem title="검사소">
					<Input
						name="inspectionShopName"
						placeholder="검사소"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={inspectionShopName}
						onChange={(e) => setInspectionShopName(e.target.value)}
						onReset={() => setInspectionShopName('')}
					/>
				</GridItem>
				<GridItem title="지급대상">
					<Input
						name="paymentName"
						placeholder="지급대상"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={paymentName}
						onChange={(e) => setPaymentName(e.target.value)}
						onReset={() => setPaymentName('')}
					/>
				</GridItem>
				<GridItem title="사업자번호">
					<Input
						name="companyRegistrationNumber"
						placeholder="사업자번호"
						type="text"
						$inputSize="small"
						$inputRadius="small"
						value={companyRegistrationNumber}
						onChange={(e) =>
							setCompanyRegistrationNumber(
								companyRegisterNumberFormat(e.target.value),
							)
						}
						onReset={() => setCompanyRegistrationNumber('')}
					/>
				</GridItem>
			</GridLayout>
			<Button
				size="large"
				$buttonType={ButtonTypeType.GREEN}
				onClick={downloadFile}
				disabled={isLoading}
				style={{ marginTop: '10px' }}
			>
				{!isLoading ? '다운로드 받기' : '다운로드 중..'}
			</Button>
		</ContentBoxWithHeader>
	);
};

export default PaymentsExcelDownload;
